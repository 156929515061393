import './App.css';
import React, { useState } from 'react';
import { createClient } from "@supabase/supabase-js";
const supabase = createClient("https://gsxwihvqxvdmowkcjtbz.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdzeHdpaHZxeHZkbW93a2NqdGJ6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njc4NjYyNTAsImV4cCI6MTk4MzQ0MjI1MH0.YloF4cZ1iB8btEJfkyDpappqcVjFyC3DZWHsru5FFEU");



function App() {

  const MyButton = ({ onClick, label }) => {
    return (
      <button onClick={onClick}>
        {label}
      </button>
    );
  };

  const [data, setData] = useState([]);

  const handleClick = async () => {
    try {
      // Perform Supabase SELECT operation
      const { data, error } = await supabase
        .from('test_bill') // Replace 'your_table_name' with your actual table name
        .select(); // You can customize the columns you want to select

      if (error) {
        console.error('Error fetching data from Supabase:', error.message);
      } else {
        console.log('Data from Supabase:', data);
        setData(data);
        // Update state or do other operations with the retrieved data
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


  return (
    <div>
      <MyButton onClick={handleClick} label="Click me" />
      <div>
        <h2>Fetched Data:</h2>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </div>
    
  );
}

export default App;
